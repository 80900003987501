import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Selection from '../components/section'
import Sectionwhite from '../components/sectionwhite'
import Alert from '../components/alerts'


const IndexPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>


    <div className="Hero">

    <div className="espa">
          <img src={require('../Images/espalogo.png')} height="90" />
        </div>

      <div className="HeroGroup">
        <h1>
          <FormattedMessage id="Kyma" />
        </h1>
        <p>
          <FormattedMessage id="herointro" />
        </p>
    
      </div>
    </div>

    <Alert
      logo={require('../Images/alert.svg')}
      title={<FormattedMessage id="alerttitle" />}
      text={<FormattedMessage id="alerttext" />}
      button={<FormattedMessage id="alerttextbutton" />}
      link="/covid"
      />

    <Selection
      image={require('../Images/background3.jpg')}
      logo={require('../Images/bed.svg')}
      title={<FormattedMessage id="OurRooms" />}
      text={<FormattedMessage id="OurRoomsIntro" />}
      button={<FormattedMessage id="Checktherooms" />}
      link="/rooms"
    />

    <Sectionwhite
      image={require('../Images/background3.jpg')}
      logo={require('../Images/room-service.svg')}
      title={<FormattedMessage id="Services" />}
      text={<FormattedMessage id="OurServicesIntro" />}
      button={<FormattedMessage id="Checktheservices" />}
      link="/services"
    />
    <Selection
      image={require('../Images/background3.jpg')}
      logo={require('../Images/map-2.svg')}
      title={<FormattedMessage id="Location" />}
      text={<FormattedMessage id="OurLocationIntro" />}
      button={<FormattedMessage id="Checkthelocation" />}
      link="/location"
    />



  
  </Layout>
)

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired
}

export default IndexPage
