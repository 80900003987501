import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

const AlertGroup = styled.div`
  background-color: white;
  color: white;
  display: flex;
  align-items: flex-start;
  position:fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  color: blue;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 3px 10px rgba(51, 62, 73, 0.18), 0px 6px 17px rgba(51, 62, 73, 0.18);

  @media (max-width: 720px) {
  display: grid;
  grid-gap: 24px;
	display: none;


  }

`


const SectionLogo = styled.img`
  width: 48px;
  margin-right: 24px;


  @media (max-width: 720px) {

    margin:auto;
  
    }

`

const SectionTtitleGroup = styled.div`

  @media (max-width: 720px) {
    text-align: center;
  }
`
const SectionTitle = styled.h3`
  color: black;
  font-size: 24px;
  line-height: 1.2;
  margin:0px;

  @media (max-width: 720px) {
    font-size: 20px;
  }
`
const SectionText = styled.p`
font-size: 18px;
font-weight: 500;
color: black;
margin-top: 8px;
margin-bottom: 0px

`
const SectionButton = styled.div`
margin: 20px auto;
a {
  color: white;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 9px 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  background-color: #293a7e;
}

a:hover {
  color: white;
}
`
const Section = props => (
  <AlertGroup>
     <SectionLogo src={props.logo} />
    <SectionTtitleGroup>
      <SectionTitle> {props.title} </SectionTitle>
      <SectionText>{props.text}</SectionText>
      <SectionButton>
      <LocalizedLink to={props.link}>
        <FormattedMessage id={props.button} />
      </LocalizedLink>
    </SectionButton>
      </SectionTtitleGroup>
    
  </AlertGroup>
)

export default Section
